import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 메일링 등록
const mailingEdit = (params) => {
  return http.post(SERVICE_API_URL + `/mailing`, params, {
    headers: authHeader(),
  });
};

export default {
  mailingEdit,
};
