<template>
  <div class="faq_popup">
    <div class="pfaq_popup popup">
      <div class="pfaq_popup_content popup_content">
        <div class="close_btn" @click="close">
          <span></span>
          <span></span>
        </div>
        <h3>
          빠르고 친절한 1:1문의 <span>빠르고 친절한 답변을 드립니다.</span>
        </h3>
        <div class="pfaq_top">
          <div class="pfaq_top_wrap">
            <p><span>{{getMember}}</span>님 무엇을 도와드릴까요?</p>
            <button @click="openQnaDetail">문의내역</button>
          </div>
          <form @submit.prevent="formSubmit" method="post">
            <ValidationProvider
              name="문의구분"
              rules="required"
              ref="refDivision"
            >
              <select
                name="pfaq_division"
                id="pfaq_division"
                v-model="category"
                required
              >
                <option value="" disabled selected>
                  문의구분을선택해주세요
                </option>
                <option v-for="(qna, idx) in qnas" :key="idx" :value="qna.cd">
                  {{ qna.nm }}
                </option>
              </select>
            </ValidationProvider>
            <ValidationProvider
              name="문의내용"
              rules="required"
              ref="refDescription"
            >
              <textarea
                name="pfaq_description"
                id="pfaq_description"
                v-model="question_content"
                placeholder="문의 내용을 작성해 주세요."
              ></textarea>
            </ValidationProvider>
            <p class="attachments">
              <input
                type="text"
                v-model="fileName"
                placeholder=" 첨부파일은 500KB까지 업로드 가능 합니다."
              />
              <label for="selectFile" class="input_file">
                <input
                  type="file"
                  id="selectFile"
                  accept=".zip, .gz, .tgz .xls*, .ppt*, .doc*, .hwp, .txt, .png, .jpg, .jpeg .pdf, .bmp, .gif"
                  ref="selectFile"
                  @change="onChangeFile"
                />
                파일 찾기
              </label>
            </p>
            <ValidationProvider
              name="이메일"
              rules="required|email"
              ref="refEmail"
            >
              <input
                type="email"
                placeholder="이메일 주소를 입력해 주세요."
                class="pfaq_email"
                v-model="email"
              />
            </ValidationProvider>
            <p class="pfaq_agree">
              <ValidationProvider
                name="개인정보 수집 및 이용 동의"
                rules="required"
                ref="refTerms"
              >
                <input
                  type="checkbox"
                  v-model="check_term"
                  id="terms_confirm"
                />
              </ValidationProvider>
              <label for="terms_confirm">개인정보 수집 및 이용 동의</label>
              <span @click="openPolicy" class="terms_detail">자세히 보기</span>
            </p>
            <p class="pfaq_agree margin_top_20">
              산업안전보호법에 따른 고객 응대 근로자 보호조치에 의거하여 폭언,
              욕설 등이 포함된 내용은 상담이 제한될 수 있습니다.
            </p>
            <div class="faq_btn">
              <button type="submit">문의하기</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";
import QnaDetailPopup from "@/components/QnaDetailPopup.vue";
import Policy from "@/components/Policy.vue";

export default {
  data() {
    return {
      category: "",
      question_content: "",
      fileName: "",
      selectFile: "",
      email: "",
      qnas: [],
      attachCd: "",
      check_term: "",
    };
  },
  created() {
    this.read();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getMember"]),
  },
  methods: {
    async formSubmit() {
      let isValidation = await this.validation();
      if (isValidation) {
        this.create();
      }
    },
    async validation() {
      const refDivision = await this.$refs.refDivision.validate(),
        refDescription = await this.$refs.refDescription.validate(),
        refEmail = await this.$refs.refEmail.validate(),
        refTerms = await this.$refs.refTerms.validate();

      if (!refDivision.valid) {
        alert(refDivision.errors[0]);
        return false;
      }

      if (!refDescription.valid) {
        alert(refDescription.errors[0]);
        document.getElementById("pfaq_description").focus();
        return false;
      }

      if (!refEmail.valid) {
        alert(refEmail.errors[0]);
        document.querySelector(".pfaq_email").focus();
        return false;
      }

      if (!refTerms.valid) {
        alert(refTerms.errors[0]);
        return false;
      }

      return true;
    },
    async create() {
      let form = new FormData();
      form.append("attach", this.selectFile);

      await client.attachUpload(form).then(
        (response) => {
          this.attachCd = response.data[0].attachCd;
        },
        (error) => {
          console.log(error);
        }
      );

      let params = {
        categoryCd: this.category,
        email: this.email,
        questionContent: this.question_content,
        attachCd: this.attachCd,
      };
      await client.boardQnaQuestionReg(params).then(
        () => {
          alert(
            "문의하신 내용이 접수되었습니다. 관리자 확인 후 최대한 빠른 답변 드리도록 하겠습니다. 감사합니다."
          );
          this.close();
        },
        (error) => {
          alert(error);
        }
      );
    },
    read() {
      client.codeList("QNA").then(
        (response) => {
          const { data } = response;
          this.qnas = data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    onChangeFile(e) {
      if (0 < this.$refs.selectFile.files.length) {
        this.selectFile = this.$refs.selectFile.files[0];
        let fileExt = this.selectFile.name.substring(
          this.selectFile.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          this.selectFile.size <= 512000 &&
          [
            "doc",
            "docx",
            "pdf",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
          ].includes(fileExt)
        ) {
          const file = e.target.files[0];
          this.fileName = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile = null;
        }
      }
    },
    close() {
      this.$emit("close");
    },
    openQnaDetail() {
      this.$modal.show(
        QnaDetailPopup,
        {},
        { width: 720, height: "auto" },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    openPolicy() {
      this.$modal.show(
        Policy,
        {},
        {
          width: 1000,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
  },
};
</script>
