<template>
  <div class="faq_detail">
    <div class="pfaq_detail_popup popup">
      <div class="pfaq_detail_popup_content popup_content">
        <div class="close_btn" @click="closeDetail">
          <span></span>
          <span></span>
        </div>
        <h3>
          빠르고 친절한 1:1문의 <span>빠르고 친절한 답변을 드립니다.</span>
        </h3>
        <div class="pfaq_detail_wrap">
          <div class="faq_detail_btn">
            <button @click="goQnaPopup">문의하기</button>
          </div>
          <ul class="faq_detail_list">
            <li v-for="(item, idx) in items" :key="idx">
              <span v-if="item.category">[{{ item.category.nm }}] </span>
              <span>{{ item.questionDtTm }}</span>
              <p>Q. {{ item.questionContent }}</p>
              <div class="popup_answer white_space">
                <div>A.</div>
                <div>{{ item.answerContent }}</div>
              </div>
              <div class="test">
                <img
                  src="@/assets/icons/pdetail-down-arrow.png"
                  alt="open"
                  @click="toggle(idx)"
                  class="icon_down"
                />
                <img
                  src="@/assets/icons/pdetail-up-arrow.png"
                  alt="close"
                  @click="toggle(idx)"
                  class="icon_up"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      open: false,
      answer: false,
      items: [],
      isActive: false,
    };
  },
  created() {
    this.read();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    read() {
      client.boardQnaListUser().then(
        (response) => {
          const { data } = response;
          this.items = data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    closeDetail() {
      this.$emit("close");
    },
    goQnaPopup() {
      this.$emit("close");
    },
    toggle(idx) {
      document.querySelectorAll(".popup_answer").forEach(function (e, i) {
        if (idx == i) {
          e.classList.toggle("active");
        }
      });
      document.querySelectorAll(".icon_down").forEach(function (e, i) {
        if (idx == i) {
          e.classList.toggle("active");
          document.querySelectorAll(".icon_up").forEach(function (el, idx) {
            if (i == idx) {
              el.classList.toggle("active");
            }
          });
        }
      });
    },
  },
};
</script>
