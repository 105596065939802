import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 첨부파일 업로드
const attachUpload = (params) => {
  return http.post(SERVICE_API_URL + `/attach`, params, {
    headers: authHeader(),
  });
};

// 첨부파일 다운로드
const attachDownload = (attach_cd) => {
  return http.get(SERVICE_API_URL + `/attach/${attach_cd}`, {
    headers: authHeader(),
  });
};

export default {
  attachUpload,
  attachDownload,
};
