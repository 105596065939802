import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import VueDayjs from "vue-dayjs-plugin";
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/i18n/ko-kr";
import { ValidationProvider, extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import VueClipboard from "vue-clipboard2";
import vmodal from "vue-js-modal";

Vue.use(vmodal);
Vue.use(VueClipboard);
Vue.use(VueDayjs);
Vue.component("date-picker", DatePicker);
Vue.use(BootstrapVue);
Vue.component("ValidationProvider", ValidationProvider);
Vue.config.productionTip = false;

for (let rule in rules) {
  extend(rule, rules[rule]);
}

extend("email", {
  message: "잘못된 이메일 주소 입니다.",
});

extend("required", {
  message: "{_field_}을(를) 입력해 주세요.",
});

extend("confirmed", {
  message: "비밀번호가 일치하지 않습니다.",
});

extend("alpha_dash", {
  message: "{_field_}는 영문, 숫자, 특수기호(-),(_)만 사용 가능합니다.",
});

extend("numeric", {
  message: "{_field_}는 숫자만 입력 가능합니다.",
});

extend("min", {
  validate(value, { min }) {
    if (value.length >= min) {
      return true;
    }
    return "{_field_}는 {min}자리 이상이어야 합니다.";
  },
  params: ["min"],
});

extend("max", {
  validate(value, { max }) {
    if (value.length <= max) {
      return true;
    }
    return "{_field_}는 {max}자리 이하여야 합니다.";
  },
  params: ["max"],
});

extend("password", {
  validate: (value) => {
    let regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/.test(value);
    if (!regex) {
      return "비밀번호는 영문, 숫자포함 8자리 이상 20자리 이하여야 합니다.";
    } else {
      return true;
    }
  },
});

import ko from "vee-validate/dist/locale/ko.json";
localize({
  ko,
});
localize("ko");

import VModal from "vue-js-modal";
Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
