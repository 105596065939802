import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 프로젝트 지원자 목록
const projectApplyList = (project_cd, params) => {
  return http.get(SERVICE_API_URL + `/project/apply/${project_cd}`, {
    params: params,
    headers: authHeader(),
  });
};

// 프로젝트 지원자 등록
const projectApplyReg = (project_cd, params) => {
  return http.post(SERVICE_API_URL + `/project/apply/${project_cd}`, params, {
    headers: authHeader(),
  });
};

// 프로젝트 선정자 목록
const projectApplySelectionList = (project_cd, params) => {
  return http.get(SERVICE_API_URL + `/project/apply/${project_cd}/selection`, {
    params: params,
    headers: authHeader(),
  });
};

// 프로젝트 선정자 등록
const projectApplySelectionReg = (project_cd, seq, params) => {
  return http.post(
    SERVICE_API_URL + `/project/apply/${project_cd}/selection/${seq}`,
    params,
    {
      headers: authHeader(),
    }
  );
};

// 프로젝트 선정자 취소
const projectApplySelectionCancel = (project_cd, seq) => {
  return http.delete(
    SERVICE_API_URL + `/project/apply/${project_cd}/selection/${seq}`,
    {
      headers: authHeader(),
    }
  );
};

// 프로젝트 진행 목록(수)
const projectApplyUser = (member_id, params) => {
  return http.get(SERVICE_API_URL + `/project/apply/user/${member_id}`, {
    params: params,
    headers: authHeader(),
  });
};

export default {
  projectApplyList,
  projectApplyReg,
  projectApplySelectionList,
  projectApplySelectionReg,
  projectApplySelectionCancel,
  projectApplyUser,
};
