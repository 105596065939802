<template>
  <div id="app">
    <Header />
    <router-view :key="$route.fullPath" />
    <Footer />
  </div>
</template>

<script>
import "@/assets/css/style.css";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
#app {
  width: 100%;
  min-width: 380px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  position: fixed;
  bottom: 0;
  top: 0;
}
</style>
