import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const login = (params) => {
  return http.post(SERVICE_API_URL + "/member/user/login", params, {
    headers: authHeader(),
  });
};

const logout = () => {
  return http.get(SERVICE_API_URL + "/member/user/logout", {
    headers: authHeader(),
  });
};

// const user = () => {
//   return http.get(SERVICE_API_URL + "/member/user/login", {
//     headers: authHeader(),
//   });
// };

export default {
  login,
  logout,
  // user,
};
