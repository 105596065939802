import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 제휴/광고문의 전체 데이터 수
const boardContactTotalCount = (params) => {
  return http.get(SERVICE_API_URL + `/board/contact/totalcount`, {
    params: params,
    headers: authHeader(),
  });
};

// 제휴/광고문의 목록
const boardContactList = (params) => {
  return http.get(SERVICE_API_URL + `/board/contact`, {
    params: params,
    headers: authHeader(),
  });
};

// 제휴/광고문의 등록
const boardContactReg = (params) => {
  return http.post(SERVICE_API_URL + `/board/contact`, params, {
    headers: authHeader(),
  });
};

// 제휴/광고문의 보기
const attachDownload = (seq) => {
  return http.get(SERVICE_API_URL + `/board/contact/${seq}`, {
    headers: authHeader(),
  });
};

// 제휴/광고문의 삭제
const boardContactDel = (seq) => {
  return http.delete(SERVICE_API_URL + `/board/notice/${seq}`, {
    headers: authHeader(),
  });
};

export default {
  boardContactTotalCount,
  boardContactList,
  boardContactReg,
  attachDownload,
  boardContactDel,
};
