import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 회원 전체 데이터 수 (사용자)
const memberUserTotalCount = (params) => {
  return http.get(SERVICE_API_URL + `/member/user/totalcount`, {
    params: params,
    headers: authHeader(),
  });
};

// 회원 목록 (사용자)
const memberUserList = (params) => {
  return http.get(SERVICE_API_URL + `/member/user`, {
    params: params,
    headers: authHeader(),
  });
};

// 아이디 중복확인 (사용자/관리자)
const memberId = (params) => {
  return http.post(SERVICE_API_URL + `/member/id`, params, {
    headers: authHeader(),
  });
};

// 이메일 중복확인 (사용자/관리자)
const memberEmail = (params) => {
  return http.post(SERVICE_API_URL + `/member/email`, params, {
    headers: authHeader(),
  });
};

// 회원 가입 (사용자)
const memberUserReg = (params) => {
  return http.post(SERVICE_API_URL + `/member/user`, params, {
    headers: authHeader(),
  });
};

// 미팅 가능 일시 (사용자)
const memberUserMeeting = (member_id, params) => {
  return http.put(
    SERVICE_API_URL + `/member/user/meeting/${member_id}`,
    params,
    {
      headers: authHeader(),
    }
  );
};

// 회원 수정 (사용자)
const memberUserMod = (member_id, params) => {
  return http.put(SERVICE_API_URL + `/member/user/${member_id}`, params, {
    headers: authHeader(),
  });
};

// 회원 정보 (사용자)
const memberUserView = (member_id) => {
  return http.get(SERVICE_API_URL + `/member/user/${member_id}`, {
    headers: authHeader(),
  });
};

// 회원 삭제 (사용자)
const memberUserDel = (member_id) => {
  return http.delete(SERVICE_API_URL + `/member/user/${member_id}`, {
    headers: authHeader(),
  });
};

// 로그인 (사용자)
const memberUserLogin = (params) => {
  return http.post(SERVICE_API_URL + `/member/user/login`, params, {
    headers: authHeader(),
  });
};

// 로그인 (관리자)
const memberAdminLogin = (params) => {
  return http.post(SERVICE_API_URL + `/member/admin/login`, params, {
    headers: authHeader(),
  });
};

// 로그아웃 (사용자)
const memberUserLogout = (params) => {
  return http.get(SERVICE_API_URL + `/member/user/logout`, {
    params: params,
    headers: authHeader(),
  });
};

// 로그아웃 (관리자)
const memberAdminLogout = (params) => {
  return http.get(SERVICE_API_URL + `/member/admin/logout`, {
    params: params,
    headers: authHeader(),
  });
};

// 아이디 찾기 (사용자)
const memberFindId = (params) => {
  return http.post(SERVICE_API_URL + `/member/find/id`, params, {
    headers: authHeader(),
  });
};

// 비밀번호 찾기 (사용자)
const memberFindPw = (params) => {
  return http.post(SERVICE_API_URL + `/member/find/pw`, params, {
    headers: authHeader(),
  });
};

export default {
  memberUserTotalCount,
  memberUserList,
  memberId,
  memberEmail,
  memberUserReg,
  memberUserMeeting,
  memberUserMod,
  memberUserView,
  memberUserDel,
  memberUserLogin,
  memberAdminLogin,
  memberUserLogout,
  memberAdminLogout,
  memberFindId,
  memberFindPw,
};
