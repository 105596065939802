<template>
  <div class="terms">
    <div class="terms_popup popup">
      <div class="terms_popup_content popup_content">
        <div class="close_btn" @click="close">
          <span></span>
          <span></span>
        </div>
        <h3>이용약관</h3>
        <div class="terms_wrap">
          <h4>제 1 조 (목적) :::</h4>
          <p>
            이 약관은 bluebutton 에서 제공하는 비교견적,아웃소싱,프리랜서열람
            서비스 (이하 '서비스'라 한다)를 이용함에 있어 이용조건 및 절차와
            이용자의 권리.의무 및 책임사항을 규정함을 목적으로 합니다.
          </p>
          <h4>제 2 조 (이용약관의 명시 , 효력 및 변경) :::</h4>
          <p>
            ① 이 약관은 bluebutton 에서 운영하는 홈페이지 및 제휴사 홈페이지에
            게시하여 공시함으로써 효력이 발생합니다. <br />
            ② 이용자가 본 약관의'동의함' 단추를 누르면 이용약관, 책임의 한계 및
            회사의 개인정보보호정책을 모두 이해하고 그 내용에 동의하는 것으로
            간주합니다.<br />
            ③ 회사가 제공하는 서비스 전반에 있어 책임의 한계와
            개인정보보호정책에 관하여는 별도 고지합니다.<br />
            ④ 회사는 약관의 규제 등에 관한 법률 , 전자거래기본법 , 전자서명법 ,
            정보통신망 이용촉진 등에 관한 법률 등 관련법을 위배하지 않는
            범위에서 합리적 사유가 있는 경우 회원의 사전동의 없이 본 약관을
            개정할 수 있습니다 .<br />
            ⑤ 본 약관의 개정시 운영사이트에 공지하는 것으로 효력이
            발생합니다.<br />
            ⑥ 본 약관에 정하는 범위 이외의 이용자와 회사의 권리 , 의무 및
            책임사항에 관해서는 전기통신사업법 기타 대한민국의 관련 법령과
            상관습을 따릅니다 .
          </p>
          <h4>제 3 조 (용어의 정의) :::</h4>
          <p>
            이 약관에서 이용하는 용어의 정의는 다음과 같습니다 . 이 약관에서
            이용하는 용어의 정의는 제 1항에서 정하는 것을 제외하고는 관계법령 및
            서비스 별 안내에서 정하는 바에 의합니다.<br />
            ① '비교견적서비스'란 이용자의 서비스 상품에 대한 견적요청에 대해
            다수의 프리랜서,업체들이 지원서(견적서)를 제출하게 하여 상호간의
            제작, 계약을 돕는서비스로써 해당 비교견적 서비스에 있어 회사는
            거래에 직접 관여하지 않습니다.<br />
            ② 프로젝트’아웃소싱’이란 이용자가 등록한 프로젝트에 다수의 지원자를
            선택 후 3자입장에서 간접적으로 프로젝트에 참여하여 결재대금 보호,
            이용약관에 의거 안정적 프로젝트 진행이 가능하도록 서포트하는
            서비스입니다.<br />
            ③ ‘클라이언트’란 작업정보를 제공하여 프로젝트를 진행/완료를 원하는
            고객을 말합니다.<br />
            ④ '이용자'란 회사가 제공하는 일체의 서비스를 받는 자를 말합니다 .<br />
            ⑤ '웹프리랜서, 업체'란 이용자의 견적요청에 대해 상담서비스를
            제공하는 자를 말합니다.<br />
            ⑥ '고객인증'이란 장난, 허위 견적의뢰를 방지하기 위한 방법으로
            이용자의 휴대폰 및 이메일로 특정 번호를 송신하고 그 번호를 다시 입력
            받는 것을 말합니다 .<br />
            ⑦ '비밀번호'란 재이용자의 고객인증에 따른 불편함을 없애기 위해
            이용자의 일치여부를 판명하는 데 사용되는 이용자가 선정한 문자와
            숫자의 조합을 말합니다.<br />
            ⑧ '웹제작컨설팅'이란 이용자가 웹제작에 프로세스의 명확한 정의없이
            프리랜서 업체 계약시 당할수 있는 손해를 방지코저 oejunara 웹전문가의
            도움으로 수월한 웹제작이 이루어지도록 하는 서비스를 말합니다.<br />
            ⑨ ‘열람서비스’란 bluebutton의 아웃소싱 대행 서비스를 이용하지 않고
            클라이언트나,프리랜서 스스로가 상대방과 연락을 취하여 프로젝트를
            진행하는 방식으로 상대방의 연락처를 열람하기위한 일련의
            서비스입니다.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
