// store
import store from "../../store";

export default function authHeader() {
  const accessToken = store.getters["getAccessToken"];

  if (accessToken) {
    return {
      "X-AUTH-TOKEN": accessToken,
    };
  } else {
    return {};
  }
}
