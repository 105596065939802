import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 하위코드 목록
const codeList = (up_cd, params) => {
  return http.get(SERVICE_API_URL + `/code/search/${up_cd}`, {
    params: params,
    headers: authHeader(),
  });
};

// 코드 정보
const codeView = (cd, params) => {
  return http.get(SERVICE_API_URL + `/code/${cd}`, {
    params: params,
    headers: authHeader(),
  });
};

export default {
  codeList,
  codeView,
};
