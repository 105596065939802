<template>
  <div class="terms">
    <div class="terms_popup popup">
      <div class="terms_popup_content popup_content">
        <div class="close_btn" @click="close">
          <span></span>
          <span></span>
        </div>
        <h3>개인정보취급방침</h3>
        <div class="terms_wrap">
          <h4>1. 개인 정보 수집ㆍ이용목적</h4>
          <p>
            ① bluebutton는 이용자의 정보수집 시 서비스 제공에 필요한 최소한의
            정보를 수집합니다.
            <br />
            ② bluebutton가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는
            이용자의 동의를 받습니다.
            <br />
            ③ 제공된 개인정보는 당해 이용자의 동의 없이 제3자에게 제공할 수
            없으며, 이에 대한 모든 책임은 bluebutton가 집니다. 다만, 다음의
            경우에는 예외로 합니다. ⅰ. 통계작성, 학술연구 또는 시장조사를 위하여
            필요한 경우로서 특정개인을 식별할 수 없는 형태로 제공하는 경우 ⅱ.
            이용자의 서비스 신청 시 유선 상담을 위해 지정된 업체에게 이에 필요한
            이용자의 정보를 알려주는 경우 ⅲ. 서비스관련 정보제공 및 부가서비스
            정보제공에 대한 메일전송에 해당하는 경우 ⅳ. 홈페이지 제작상담 및
            계약지원을 위해 내부규정에 의해 견적서를 제출한 업체에게 제공하는
            경우
            <br />
            ④ bluebutton가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는
            경우에는 개인정보관리 책임자의 신원 (소속, 성명 및 전화번호 기타
            연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항
            (제공받는 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망이용촉진
            등에 관한 법률 제16조 제3항이 규정한 사항을 미리 명시하거나 고지해야
            하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
            <br />
            ⑤ 수집하는 개인정보 항목에 따른 구체적인 수집목적 및 이용목적은
            다음과 같습니다. 수집한 개인 정보는 다음의 목적 이외의 용도로
            사용되지 않으며, 이용 목적이 변경될 시에는 별도의 사전동의를
            구합니다. ⅰ. 플랫폼 회원 가입 및 관리 : 회원 가입의사 확인, 회원제
            서비스 제공에 따른 본인 식별 ∙ 인증, 회원자격 유지 ∙ 관리, 제한적
            본인 확인 제 시행에 따른 본인 확인, 서비스 부정이용 방지, 만14세
            미만 아동 개인정보 수집 시 법정 대리인 동의 여부 확인, 각종 고지 ∙
            통지, 고충처리, 분쟁 조정을 위한 보존 등의 목적 ⅱ. 민원사무의 처리 :
            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락 ∙ 통지,
            처리결과 통보 등의 목적 ⅲ. 서비스의 제공 : 서비스 제공, 본인인증,
            연령인증, 채권 추심 등의 목적 ⅳ. 마케팅 및 광고에의 활용 : 신규
            서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및
            참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재,
            서비스 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한
            통계 등을 목적
          </p>
          <h4>2. 수집하는 개인정보 항목</h4>
          <p>
            bluebutton가 서비스 제공을 위해 받는 이용자의 정보는 이름, 주소,
            전화번호(핸드폰), 비밀번호, e-mail 주소 등입니다.<br />
            이 이외에 서비스 상품별 추가적인 정보를 요구하고 있습니다.<br />
            회원님께서 bluebutton에서 제공하는 서비스를 받는 동안 이용자의
            개인정보는 bluebutton에서 계속 보유하며 서비스 제공을 위해 이용하게
            됩니다. <br />
            단, bluebutton 이용절차에 따라 이용자의 정보 삭제를 요청하거나
            표명된 회원 자격 상실 사유에 의해 회원의 이용자격을 제한 및
            정지시키는 경우에는 해당 개인의 정보는 즉시 삭제 되며, 어떠한
            용도로도 열람 또는 이용될 수 없도록 처리됩니다.
          </p>
          <h4>3. 개인정보의 보유 및 이용기간</h4>
          <p>
            원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
            지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로
            명시한 기간 동안 보존합니다.
            <br />
            ① 회원탈퇴 시 보존 개인정보<br />
            - 보존항목: 회원님께서 제공한 이름, 아이디, 이메일주소, 주소,
            전화번호
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
