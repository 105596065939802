import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 1:1문의 전체 데이터 수
const boardQnaTotalCount = (params) => {
  return http.get(SERVICE_API_URL + `/board/qna/totalcount`, {
    params: params,
    headers: authHeader(),
  });
};

// 1:1문의 목록
const boardQnaList = (params) => {
  return http.get(SERVICE_API_URL + `/board/qna`, {
    params: params,
    headers: authHeader(),
  });
};

// 1:1문의 내역
const boardQnaListUser = () => {
  return http.get(SERVICE_API_URL + `/board/qna/user`, {
    headers: authHeader(),
  });
};

// 1:1문의 질문
const boardQnaQuestionReg = (params) => {
  return http.post(SERVICE_API_URL + `/board/qna/q`, params, {
    headers: authHeader(),
  });
};

// 1:1문의 답변
const boardQnaAnswerEdit = (seq, params) => {
  return http.post(SERVICE_API_URL + `/board/qna/a/${seq}`, params, {
    headers: authHeader(),
  });
};

// 1:1문의 보기
const boardQnaView = (seq) => {
  return http.get(SERVICE_API_URL + `/board/qna/${seq}`, {
    headers: authHeader(),
  });
};

// 1:1문의 삭제
const boardQnaDel = (seq) => {
  return http.delete(SERVICE_API_URL + `/board/qna/${seq}`, {
    headers: authHeader(),
  });
};

export default {
  boardQnaTotalCount,
  boardQnaList,
  boardQnaListUser,
  boardQnaQuestionReg,
  boardQnaAnswerEdit,
  boardQnaView,
  boardQnaDel,
};
