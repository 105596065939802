<template>
  <footer>
    <section class="footer_container">
      <div class="footer_cs">
        <p v-if="false">
          <a href="tel:02-6337-0310">02-6337-0310</a> |
          <a href="mailto:next@nextinnovation.kr">next@nextinnovation.kr</a>
        </p>
        <p>Copyright &copy; BLUE BUTTON. All rights reserved.</p>
      </div>
    </section>
    <section class="alliance">
      <!-- <h2>제휴, 광고문의</h2> -->
      <p>
        <span class="footer_terms" @click="openTerms">이용약관</span> |
        <span class="footer_policy" @click="openPolicy">개인정보취급방침</span>
        | 개인정보취급책임자 : 전병훈
      </p>
      <p>Copyright &copy; BLUE BUTTON. All rights reserved.</p>
    </section>
  </footer>
</template>

<script>
import Terms from "@/components/Terms.vue";
import Policy from "@/components/Policy.vue";
import QnaPopup from "@/components/QnaPopup.vue";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    openQnaPopup() {
      if (!this.isAuthenticated) {
        if (confirm("로그인이 필요합니다. 로그인을 하시겠습니까?")) {
          this.$router.push({
            name: "Login",
          });
        }
        return;
      }
      this.$modal.show(
        QnaPopup,
        {},
        {
          width: 720,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    openPolicy() {
      this.$modal.show(
        Policy,
        {},
        {
          width: 1000,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    openTerms() {
      this.$modal.show(
        Terms,
        {},
        {
          width: 1000,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
  },
};
</script>
