import Vue from "vue";
import VueRouter from "vue-router";

// store
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("@/views/Main.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      authRequired: false,
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/findId",
    name: "FindId",
    meta: {
      authRequired: false,
    },
    component: () => import("@/views/FindId.vue"),
  },
  {
    path: "/findpw",
    name: "FindPassword",
    meta: {
      authRequired: false,
    },
    component: () => import("@/views/FindPassword.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    meta: {
      authRequired: false,
    },
    component: () => import("@/views/SignUp.vue"),
  },
  {
    path: "/qna",
    name: "QnA",
    meta: {},
    component: () => import("@/views/QnA.vue"),
  },
  // {
  //   path: "/scomplete",
  //   name: "SignupComplete",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("@/views/SignupComplete.vue"),
  // },
  {
    path: "/mybutton",
    name: "MyButton",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/MyButton.vue"),
  },
  {
    path: "/project",
    name: "Project",
    meta: {},
    component: () => import("@/views/Project/List/index.vue"),
  },
  {
    path: "/project/:seq",
    name: "ProjectSeq",
    meta: {},
    component: () => import("@/views/Project/View/index.vue"),
  },
  {
    path: "/notice",
    name: "Notice",
    meta: {},
    component: () => import("@/views/Notice/List/index.vue"),
  },
  {
    path: "/notice/:seq",
    name: "NoticeSeq",
    meta: {},
    component: () => import("@/views/Notice/View/index.vue"),
  },
  {
    path: "/partner",
    name: "Partner",
    meta: {},
    component: () => import("@/views/Partner.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "router-link-active",
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["isAuthenticated"];
  console.log(router);
  console.log(from);
  console.log(to);

  if (Object.prototype.hasOwnProperty.call(to.meta, "authRequired")) {
    if (
      to.matched.some(function (routeInfo) {
        return routeInfo.meta.authRequired;
      })
    ) {
      // 인증이 필요한 페이지일 경우 인증 체크
      if (isAuthenticated) {
        console.log("1");
        next();
      } else {
        console.log("2");
        next({ name: "Login" });
      }
    } else {
      if (isAuthenticated) {
        console.log("3");
        next({ name: "MyButton" });
      } else {
        console.log("4");
        next();
      }
    }
  } else {
    // 로그인 유뮤 체크하지 않음
    next();
  }
});

export default router;
