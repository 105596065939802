import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 프로젝트 목록
const projectList = (params) => {
  return http.get(SERVICE_API_URL + `/project`, {
    params: params,
    headers: authHeader(),
  });
};

// 프로젝트 정보
const projectView = (seq) => {
  return http.get(SERVICE_API_URL + `/project/${seq}`, {
    headers: authHeader(),
  });
};

// 이전 프로젝트
const projectPrev = (seq) => {
  return http.get(SERVICE_API_URL + `/project/${seq}/prev`, {
    headers: authHeader(),
  });
};

// 다음 프로젝트
const projectNext = (seq) => {
  return http.get(SERVICE_API_URL + `/project/${seq}/next`, {
    headers: authHeader(),
  });
};

export default {
  projectList,
  projectView,
  projectPrev,
  projectNext,
};
