<template>
  <header>
    <div class="header_container">
      <nav>
        <div class="logo">
          <router-link :to="{ name: 'Main' }">
            <img src="@/assets/images/bl-ue-button.png" alt="logo" />
          </router-link>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      waitRequest: false,
      openNav: false,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  watch: {
    $route() {
      this.openNav = false;
    },
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0;
      });
    },
    toggle() {
      this.openNav = !this.openNav;
    },
    logout() {
      if (!this.waitRequest) {
        this.waitRequest = true;
        this.$store
          .dispatch("logout")
          .then((response) => {
            console.log(response);
            this.waitRequest = false;
            if (response.status == 200) {
              this.$router.push({
                name: "Main",
              });
            }
          })
          .catch((response) => {
            this.waitRequest = false;
            alert(response.data.message);
          });
      } else {
        alert("로그아웃 중입니다.");
      }
    },
  },
};
</script>
