import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 회원 업무분야 상세정보
const getMemberUserWorkDetail = (params) => {
  return http.get(SERVICE_API_URL + `/member/user/work/detail`, {
    params: params,
    headers: authHeader(),
  });
};

// 회원 업무분야 상세 등록
const postMemberUserWorkDetail = (params) => {
  return http.post(SERVICE_API_URL + `/member/user/work/detail`, params, {
    headers: authHeader(),
  });
};

export default {
  getMemberUserWorkDetail,
  postMemberUserWorkDetail,
}