import Vue from "vue";
import Vuex from "vuex";
import services from "@/services";
import Jwt from "@/common/jwt";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: {
      accessToken: Jwt.getAccessToken(),
    }, // 토큰정보
    member: Jwt.getMember(),
    memberInfo: {},
    isAuthenticated: !!Jwt.getAccessToken(),
  },
  mutations: {
    login: function (state, payload = {}) {
      state.token.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      Jwt.saveAccessToken(payload.accessToken);
      Jwt.saveMember(payload.memberId);
    },
    member: function (state, payload = {}) {
      state.member = payload.memberId;
      state.memberInfo = payload.member;
      Jwt.saveMember(payload.memberId);
    },
    logout: function (state) {
      state.token.accessToken = "";
      state.isAuthenticated = false;
      Jwt.destroyAccessToken();
    },
  },
  getters: {
    getMember: function (state) {
      return state.member;
    },
    getAccessToken: function (state) {
      return state.token.accessToken;
    },
    isAuthenticated: function (state) {
      return state.isAuthenticated;
    },
  },
  actions: {
    logout: function (context) {
      return new Promise((resolve, reject) => {
        //  로그인 API
        services.authLogout().then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
              context.commit("logout");
            }
            resolve(response);
          },
          (error) => {
            // status 404
            if (error.response) {
              reject(error.response);
            } else {
              reject({
                data: {
                  message: "다시 시도해주세요.",
                },
              });
            }
          }
        );
      });
    },
    member: function (context, payload) {
      return new Promise((resolve, reject) => {
        services.memberUserView(payload.member_id).then(
          (response) => {
            const { data } = response;
            console.log(data);
            if (response.status == 200) {
              context.commit("member", {
                member: data,
                memberId: payload.member_id,
              });
              router.push({
                name: "MyButton",
              });
            }
            resolve(response);
          },
          (error) => {
            // status 404
            if (error.response) {
              reject(error.response);
            } else {
              reject({
                data: {
                  message: "다시 시도해주세요.",
                },
              });
            }
          }
        );
      });
    },
    login: function (context, payload) {
      const params = new FormData();
      params.append("member_id", payload.member_id);
      params.append("member_pw", payload.member_pw);

      return new Promise((resolve, reject) => {
        //  로그인 API
        services.authLogin(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              context.commit("login", {
                memberId: payload.member_id,
                accessToken: data,
              });
              context
                .dispatch("member", {
                  member: data,
                  member_id: payload.member_id,
                })
                .then(() => {})
                .catch((response) => {
                  alert(response.data.message);
                });
            }
            resolve(response);
          },
          (error) => {
            // status 404
            if (error.response) {
              reject(error.response);
            } else {
              reject({
                data: {
                  message: "다시 시도해주세요.",
                },
              });
            }
          }
        );
      });
    },
    register: function (context, payload) {
      const params = payload;
      return new Promise((resolve, reject) => {
        services.memberUserReg(params).then(
          (response) => {
            console.log("======register=======");
            console.log(response);
            if (response.status == 200) {
              context.dispatch("login", {
                member_id: params.memberId,
                member_pw: params.memberPw,
              });
            }
            resolve(response);
          },
          (error) => {
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
  },
  modules: {},
});
